import { Link, useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { styled } from "../../../stitches.config";
import { Layout } from "../../layout";
import MenuArrow from "../../../static/icons/common/menu-arrow.svg";
import { navigate } from "gatsby";
import { Seo } from "../../components/shared/Seo";

export const NotFoundView: React.FC = () => {
  const { t } = useI18next();

  return (
    <Layout>
      <Seo title={t("notFound")} />
      <NotfoundSection>
        <Container>
          <Error>404</Error>
          <ErrorText>{t("notFound")}</ErrorText>
          <ButtonsSection>
            <ReturnToPrevious onClick={() => navigate(-1)}>
              <span>{t("goToPrevious")}</span>
              <MenuArrow />
            </ReturnToPrevious>
            <ReturnToMain to="/">
              <span>{t("returnToMain")}</span>
              <MenuArrow />
            </ReturnToMain>
          </ButtonsSection>
        </Container>
      </NotfoundSection>
    </Layout>
  );
};

const NotfoundSection = styled("section", {
  padding: "32px 12px",
  "@md": {
    minHeight: "560px",
    padding: "32px 0px",
    maxWidth: 1280,
    margin: "0 auto",
  },
});
const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "$secondaryBackground",
  padding: "48px 16px",
  borderRadius: "12px",
  justifyContent: "center",
  "@md": {
    padding: "64px 32px",
    minHeight: "640px",
  },
});
const Error = styled("h1", {
  lineHeight: 1.2,
  fontWeight: 800,
  fontSize: "96px",
  textAlign: "center",
  color: "$accent",
  margin: "0px auto 12px auto",
  display: "inline-block",
  padding: "16px 32px 16px 32px",
  borderBottom: "1px solid $footer",
  "@md": {
    fontSize: "156px",
  },
});
const ErrorText = styled("h2", {
  lineHeight: 1.5,
  margin: "16px 0px 0px 0px",
  fontSize: "24px",
  textAlign: "center",
  color: "$heading",
  "@md": {
    fontSize: "36px",
  },
});
const ReturnToMain = styled(Link, {
  fill: "$text",
  color: "$text",
  padding: "12px 24px",
  backgroundColor: "$white",
  display: "flex",
  textDecoration: "none",
  gap: 16,
  justifyContent: "space-between",
  borderRadius: "24px",
  fontSize: "16px",
  alignItems: "center",
  transition: "all 0.5s ease",
  border: "1px solid $accent",

  fontWeight: 600,
  "&>svg": {
    transition: "all 0.5s ease",
  },
  "&:hover": {
    backgroundColor: "$accent",
    color: "$white",
    "&>svg": {
      fill: "$white",
    },
  },
});

const ButtonsSection = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 12,
  marginTop: "32px",
  marginBottom: 16,
  "@md": {
    flexDirection: "row",
  },
});

const ReturnToPrevious = styled("button", {
  fill: "$text",
  color: "$text",
  padding: "12px 24px",
  backgroundColor: "$white",
  display: "flex",
  textDecoration: "none",
  cursor: "pointer",
  fontSize: "16px",
  gap: 16,
  justifyContent: "space-between",
  flexDirection: "row-reverse",
  borderRadius: "24px",
  alignItems: "center",
  transition: "all 0.5s ease",
  border: "1px solid $accent",
  fontWeight: 600,
  "&>svg": {
    transition: "all 0.5s ease",
    transform: "rotate(180deg)",
  },
  "&:hover": {
    backgroundColor: "$accent",
    color: "$white",
    "&>svg": {
      fill: "$white",
    },
  },
});
