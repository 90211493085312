import { graphql } from "gatsby";
import React from "react";
import { NotFoundView } from "../views/NotFoundView/NotFoundView";

export default function NotFoundPage() {
  return <NotFoundView />;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
